import React, { useRef } from "react";
import { FaSearch, FaChevronDown, FaTimes } from "react-icons/fa";
import "./SearchAndFilter.css";

const SearchAndFilter = ({
  searchQuery,
  onSearch,
  activeTab,
  setActiveTab,
  isDropdownOpen,
  setIsDropdownOpen,
  overdueMessages,
  activeFilters,
  onTagClick,
  onClearFilters,
}) => {
  const dropdownRef = useRef(null);

  const categories = [
    { id: "all", label: "All" },
    { id: "reading", label: "Reading" },
    { id: "note", label: "Notes" },
    { id: "person", label: "People" },
    { id: "wishlist", label: "Wishlist" },
    { id: "recipe", label: "Recipes" },
    { id: "place", label: "Places" },
    { id: "music", label: "Music" },
    { id: "other", label: "Other" },
  ];

  return (
    <div className="header-controls">
      <div className="search-bar">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search messages..."
          value={searchQuery}
          onChange={onSearch}
        />
      </div>

      <div className="category-dropdown" ref={dropdownRef}>
        <button
          className="dropdown-trigger"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
          <FaChevronDown
            className={`chevron ${isDropdownOpen ? "open" : ""}`}
          />
        </button>

        {isDropdownOpen && (
          <div className="dropdown-menu">
            {categories.map((category) => (
              <button
                key={category.id}
                className={activeTab === category.id ? "active" : ""}
                onClick={() => {
                  setActiveTab(category.id);
                  setIsDropdownOpen(false);
                }}
              >
                {category.label}
              </button>
            ))}
          </div>
        )}
      </div>

      <button
        className={`overdue-button ${activeTab === "overdue" ? "active" : ""}`}
        onClick={() => setActiveTab("overdue")}
      >
        Overdue{" "}
        {overdueMessages.length > 0 && (
          <span className="overdue-count">({overdueMessages.length})</span>
        )}
      </button>

      {activeFilters.length > 0 && (
        <div className="active-filters">
          <span>Filters:</span>
          {activeFilters.map((filter) => (
            <span
              key={filter}
              className="filter-tag"
              onClick={() => onTagClick(filter)}
            >
              {filter} <FaTimes />
            </span>
          ))}
          <button className="clear-filters" onClick={onClearFilters}>
            Clear
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchAndFilter;
