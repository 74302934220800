import axios from "axios";

// Use environment variable or fallback to localhost for development
const API_BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const fetchUserInfo = () => api.get("/users/me");
export const fetchMessages = async (skip = 0, limit = 50) => {
  const response = await api.get(`/messages/?skip=${skip}&limit=${limit}`);
  return response;
};
export const fetchTags = () => api.get("/tags/");
export const deleteMessage = (messageId) =>
  api.delete(`/messages/${messageId}`);
export const markMessageAsDone = (messageId) =>
  api.put(`/messages/${messageId}/mark-done`);
export const addTag = (tag) => api.post("/tags/", { tag });
export const deleteTag = (tag) => api.delete("/tags/", { data: { tag } });
export const updateMessageTags = (messageId, tags) =>
  api.put(`/messages/${messageId}/tags`, tags);
export const updateMessageType = (messageId, newType) =>
  api.put(`/messages/${messageId}/type`, { message_type: newType });
export const updateMessage = (messageId, messageData) =>
  api.put(`/messages/${messageId}`, messageData);

export const snoozeMessage = async (messageId, date) => {
  // If date is null, we're clearing the reminder
  const payload = date
    ? {
        snoozed_until: new Date(date).toISOString(),
        reminder_date: new Date(date).toISOString(),
      }
    : {
        snoozed_until: null,
        reminder_date: null,
      };

  const response = await api.put(`/messages/${messageId}/snooze`, payload);
  return response.data;
};
export const fetchOverdueMessages = async () => {
  return await api.get("/messages/overdue");
};
export const updatePhoneNumber = (phoneNumber) =>
  api.put("/users/phone-number", { phone_number: phoneNumber });
export const googleLogin = (token) =>
  api.post("/users/google-login", { token });
export const updateTimezone = (timezone) =>
  api.put("/users/timezone", { timezone });
export const getTimezones = () => api.get("/users/timezones");
export default api;
