import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  updatePhoneNumber,
  updateTimezone,
  getTimezones,
} from "../services/api";
import "./PhoneSetup.css";

function PhoneSetup() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timezone, setTimezone] = useState("America/Los_Angeles");
  const [timezones, setTimezones] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        const response = await getTimezones();
        setTimezones(response.data.timezones);
      } catch (error) {
        console.error("Error fetching timezones:", error);
      }
    };
    fetchTimezones();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await updatePhoneNumber(phoneNumber);
      await updateTimezone(timezone);
      navigate("/");
    } catch (error) {
      setError(error.response?.data?.detail || "An error occurred");
    }
  };

  return (
    <div className="phone-setup-container">
      <h1>One Last Step!</h1>
      <p>
        Please enter your phone number and preferred timezone to complete the
        setup
      </p>
      <form onSubmit={handleSubmit}>
        <input
          type="tel"
          placeholder="Phone Number (e.g., +1234567890)"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <select
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          required
        >
          {timezones.map((tz) => (
            <option key={tz.value} value={tz.value}>
              {tz.label}
            </option>
          ))}
        </select>
        <button type="submit">Continue</button>
      </form>
      {error && <p className="error">{error}</p>}
      <p className="terms">
        By providing your phone number, you agree to receive text messages.
        You'll receive a welcome message with instructions. Reply STOP to opt
        out or HELP for support.
      </p>
    </div>
  );
}

export default PhoneSetup;
