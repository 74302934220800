import React, { useState, useRef, useEffect } from "react";
import "./SnoozeOptions.css";
import { useFloating, offset, flip, shift } from "@floating-ui/react";
import { createPortal } from "react-dom";

function SnoozeOptions({ onSnooze, onClose, reference, hasReminder }) {
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [customDate, setCustomDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [customTime, setCustomTime] = useState(() => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 10);
    return now.toTimeString().split(" ")[0].slice(0, 5);
  });

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    middleware: [
      offset({
        mainAxis: 4,
        crossAxis: 0,
      }),
      flip({
        fallbackPlacements: ["top-end"],
      }),
      shift({ padding: 8 }),
    ],
    elements: {
      reference: reference?.current,
    },
  });

  if (!reference?.current) return null;

  const handleSnooze = (days) => {
    const localDate = new Date();
    localDate.setDate(localDate.getDate() + days);
    localDate.setHours(23, 59, 59, 999);
    onSnooze(localDate.toISOString());
  };

  const handleCustomSnooze = () => {
    if (customDate && customTime) {
      const localDate = new Date(`${customDate}T${customTime}`);
      onSnooze(localDate.toISOString());
      setShowCustomDatePicker(false);
    }
  };

  const handleClearReminder = (e) => {
    e.stopPropagation();
    onSnooze(null);
  };

  return createPortal(
    <div
      ref={refs.setFloating}
      className="snooze-options"
      onClick={(e) => e.stopPropagation()}
      style={floatingStyles}
    >
      {hasReminder && (
        <button onClick={handleClearReminder} className="clear-reminder">
          Clear reminder
        </button>
      )}
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleSnooze(1);
        }}
      >
        Tomorrow
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleSnooze(3);
        }}
      >
        In 3 days
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleSnooze(7);
        }}
      >
        In a week
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleSnooze(30);
        }}
      >
        In a month
      </button>
      {showCustomDatePicker ? (
        <div className="custom-date-picker">
          <input
            type="date"
            value={customDate}
            onChange={(e) => {
              e.stopPropagation();
              setCustomDate(e.target.value);
            }}
            min={new Date().toISOString().split("T")[0]}
            onClick={(e) => e.stopPropagation()}
          />
          <input
            type="time"
            value={customTime}
            onChange={(e) => {
              e.stopPropagation();
              setCustomTime(e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <div className="custom-date-actions">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleCustomSnooze();
              }}
            >
              Set
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowCustomDatePicker(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowCustomDatePicker(true);
          }}
        >
          Custom date...
        </button>
      )}
    </div>,
    document.body,
  );
}

export default SnoozeOptions;
