import React, { useState, useRef, useEffect } from "react";
import {
  truncateText,
  copyToClipboard,
  formatTimestamp,
} from "../utils/helpers";
import {
  FaTimes,
  FaLink,
  FaBook,
  FaStickyNote,
  FaUser,
  FaShoppingCart,
  FaMapMarkerAlt,
  FaMusic,
  FaQuestion,
  FaChevronDown,
  FaClock,
  FaPizzaSlice,
  FaExclamationCircle,
  FaChevronUp,
} from "react-icons/fa";
import "./MessageCard.css";
import MessageModal from "./MessageModal";
import SnoozeOptions from "./SnoozeOptions";

function MessageCard({
  message,
  onDelete,
  onMarkAsDone,
  copiedLinks,
  setCopiedLinks,
  onOpenTagModal,
  onTagClick,
  onTypeChange,
  onSnooze,
  onUpdate,
  index,
  onClick,
  userTimezone,
}) {
  const [showSnoozeOptions, setShowSnoozeOptions] = useState(false);
  const snoozeRef = useRef(null); // Add ref for snooze dropdown
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showSnooze, setShowSnooze] = useState(false);
  const snoozeButtonRef = useRef(null);

  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      // Only handle click outside if snooze options are showing
      if (
        showSnoozeOptions &&
        snoozeRef.current &&
        !snoozeRef.current.contains(event.target)
      ) {
        setShowSnoozeOptions(false);
      }
    }

    // Only add the listener if snooze options are showing
    if (showSnoozeOptions) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showSnoozeOptions]); // Add showSnoozeOptions to dependency array

  const handleCopyLink = () => {
    navigator.clipboard.writeText(message.url);
    setCopiedLinks({ ...copiedLinks, [message.id]: true });
    setTimeout(() => {
      setCopiedLinks({ ...copiedLinks, [message.id]: false });
    }, 2000);
  };

  const getMessageTypeIcon = (type) => {
    switch (type.toUpperCase()) {
      case "READING":
        return <FaBook />;
      case "NOTE":
        return <FaStickyNote />;
      case "PERSON":
        return <FaUser />;
      case "WISHLIST":
        return <FaShoppingCart />;
      case "RECIPE":
        return <FaPizzaSlice />;
      case "PLACE":
        return <FaMapMarkerAlt />;
      case "MUSIC":
        return <FaMusic />;
      default:
        return <FaQuestion />;
    }
  };

  const messageTypes = [
    "READING",
    "NOTE",
    "PERSON",
    "WISHLIST",
    "PLACE",
    "RECIPE",
    "MUSIC",
    "OTHER",
  ];

  const handleTypeChange = (newType) => {
    onTypeChange(message.id, newType);
  };

  // Update the isOverdue calculation to handle UTC dates
  const isOverdue = message.is_overdue; // Use the is_overdue flag from the backend

  // Update formatLocalDateTime to explicitly handle UTC to local conversion
  function formatLocalDateTime(utcDateString) {
    if (!utcDateString) return "";

    try {
      // Parse the UTC date string
      const date = new Date(utcDateString);

      // Format the date using the user's timezone
      return date.toLocaleString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: userTimezone || "America/Los_Angeles", // Use user timezone or default
        timeZoneName: "short",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return utcDateString; // Fallback to original string if there's an error
    }
  }

  // Helper to check if a UTC date is in the future
  function isDateInFuture(utcDateString) {
    if (!utcDateString) return false;

    try {
      const date = new Date(utcDateString);
      const now = new Date();

      // Convert both dates to the user's timezone for comparison
      const dateInTz = new Date(
        date.toLocaleString("en-US", { timeZone: userTimezone }),
      );
      const nowInTz = new Date(
        now.toLocaleString("en-US", { timeZone: userTimezone }),
      );

      return dateInTz > nowInTz;
    } catch (error) {
      console.error("Error comparing dates:", error);
      return false;
    }
  }

  // When displaying a date from the backend
  const displayDate = (utcDateString) => {
    if (!utcDateString) return null;

    try {
      const date = new Date(utcDateString);
      return date.toLocaleString(undefined, {
        timeZone: userTimezone || "America/Los_Angeles",
      });
    } catch (error) {
      console.error("Error displaying date:", error);
      return utcDateString;
    }
  };

  const handleCardClick = (e) => {
    // Don't trigger click if clicking buttons, links, or select elements
    if (
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "A" ||
      e.target.tagName === "SELECT" ||
      e.target.closest("button") ||
      e.target.closest("a") ||
      e.target.closest("select") ||
      e.target.closest(".message-type-dropdown")
    ) {
      return;
    }
    onClick(); // Call the passed onClick handler
  };

  const toggleCollapse = (e) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`message-card ${isOverdue ? "overdue" : ""} ${
        isCollapsed ? "collapsed" : ""
      }`}
      onClick={handleCardClick}
    >
      <div className="message-header">
        <div className="message-controls">
          <div className="left-controls">
            {getMessageTypeIcon(message.message_type)}
          </div>
          <div className="message-title-container">
            <h3 className="message-title" data-title={message.title}>
              {message.title}
              {isOverdue && (
                <span
                  className="overdue-indicator"
                  title="This message is overdue"
                >
                  <FaExclamationCircle />
                </span>
              )}
            </h3>
          </div>
          <div className="right-controls">
            <div className="snooze-container" style={{ position: "relative" }}>
              <button
                ref={snoozeButtonRef}
                className="icon-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowSnooze(!showSnooze);
                }}
                title={
                  message.reminder_date
                    ? `Reminder: ${formatLocalDateTime(message.reminder_date)}${
                        message.snoozed_until &&
                        isDateInFuture(message.snoozed_until)
                          ? `\nSnoozed until: ${formatLocalDateTime(
                              message.snoozed_until,
                            )}`
                          : ""
                      }`
                    : "Set reminder"
                }
              >
                <FaClock />
              </button>
              {showSnooze && (
                <SnoozeOptions
                  reference={snoozeButtonRef}
                  onSnooze={(date) => {
                    onSnooze(message.id, date);
                    setShowSnooze(false);
                  }}
                  onClose={() => setShowSnooze(false)}
                  hasReminder={Boolean(
                    message.reminder_date || message.snoozed_until,
                  )}
                />
              )}
            </div>
            <button
              className="icon-button link-button"
              onClick={handleCopyLink}
              title="Copy link"
            >
              <FaLink />
            </button>
            <button
              className="icon-button delete"
              onClick={() => onDelete(message.id)}
              title="Delete"
            >
              <FaTimes />
            </button>
            <button
              className="collapse-toggle"
              onClick={toggleCollapse}
              title={isCollapsed ? "Expand" : "Collapse"}
            >
              {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          </div>
        </div>
      </div>
      {message.image_url && (
        <img
          src={message.image_url}
          alt="Preview"
          className="message-preview-image"
          onError={(e) => {
            console.error(`Error loading image: ${message.image_url}`);
            e.target.style.display = "none";
          }}
        />
      )}
      <p className="message-content">{truncateText(message.content, 100)}</p>
      {message.additional_notes && (
        <p>
          <strong>Notes:</strong> {message.additional_notes}
        </p>
      )}
      <div className="tags">
        {message.tags.map((tag, index) => (
          <span key={index} className="tag" onClick={() => onTagClick(tag)}>
            {tag}
          </span>
        ))}
        <button
          className="add-tag-button"
          onClick={() => onOpenTagModal(message.id)}
        >
          +
        </button>
      </div>
      <div className="message-footer">
        <span className="message-timestamp">
          {formatLocalDateTime(message.timestamp)}
        </span>
        {message.source && (
          <p>
            <strong>From:</strong> {message.source}
          </p>
        )}
        <div className="message-type-dropdown">
          <select
            value={message.message_type}
            onChange={(e) => {
              e.stopPropagation();
              handleTypeChange(e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
            className="type-select"
          >
            {messageTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <FaChevronDown className="dropdown-icon" />
        </div>
      </div>
    </div>
  );
}

export default MessageCard;
