import React from "react";
import "./Pagination.css";

const Pagination = ({
  currentPage,
  totalMessages,
  messagesPerPage,
  onPageChange,
}) => {
  const totalPages = Math.max(1, Math.ceil(totalMessages / messagesPerPage));

  // Don't render pagination if there's only one page
  if (totalPages === 1) {
    return null;
  }

  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        Previous
      </button>
      <span className="pagination-info">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
        className="pagination-button"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
