import React, { useState, useEffect } from "react";
import "./MessageModal.css";

function MessageModal({ message, onClose, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState({
    ...message,
    description: message.description || "",
  });
  const messageTypes = [
    "READING",
    "NOTE",
    "PERSON",
    "WISHLIST",
    "RECIPE",
    "PLACE",
    "MUSIC",
    "OTHER",
  ];

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  const handleSave = async () => {
    try {
      await onUpdate(editedMessage);
      setIsEditing(false);
      onClose();
    } catch (error) {
      console.error("Error saving message:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleCancel = () => {
    setEditedMessage({
      ...message,
      description: message.description || "",
    });
    setIsEditing(false);
  };

  return (
    <div className="message-modal-overlay" onClick={onClose}>
      <div
        className="message-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="message-modal-close" onClick={onClose}>
          ×
        </button>

        {isEditing ? (
          <div className="message-edit-form">
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                value={editedMessage.title}
                onChange={(e) =>
                  setEditedMessage({ ...editedMessage, title: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Category:</label>
              <select
                value={editedMessage.message_type}
                onChange={(e) =>
                  setEditedMessage({
                    ...editedMessage,
                    message_type: e.target.value,
                  })
                }
              >
                {messageTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Content:</label>
              <textarea
                value={editedMessage.content}
                onChange={(e) =>
                  setEditedMessage({
                    ...editedMessage,
                    content: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group">
              <label>URL:</label>
              <input
                type="text"
                value={editedMessage.url || ""}
                onChange={(e) =>
                  setEditedMessage({ ...editedMessage, url: e.target.value })
                }
              />
            </div>

            <div className="modal-actions">
              <button className="save-button" onClick={handleSave}>
                Save
              </button>
              <button className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="message-view">
            <button className="edit-button" onClick={() => setIsEditing(true)}>
              Edit
            </button>
            <h2>{message.title}</h2>
            <div className="message-type-badge">{message.message_type}</div>
            {message.image_url && (
              <img
                src={message.image_url}
                alt=""
                className="message-preview-image-full"
              />
            )}
            <p className="message-content-full">{message.content}</p>
            {message.url && (
              <a
                href={message.url}
                target="_blank"
                rel="noopener noreferrer"
                className="message-url"
              >
                {message.url}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageModal;
