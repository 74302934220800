// frontend/src/components/ConfirmationModal.js
import React from "react";
import "./ConfirmationModal.css"; // Create a CSS file for styling

function ConfirmationModal({
  isOpen,
  message,
  onConfirm,
  onCancel,
  isDarkMode,
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal-content ${isDarkMode ? "dark-mode" : ""}`}>
        <p>{message}</p>
        <button className={isDarkMode ? "dark-mode" : ""} onClick={onConfirm}>
          Yes
        </button>
        <button className={isDarkMode ? "dark-mode" : ""} onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
}

export default ConfirmationModal;
