import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../services/api";
import "./SignIn.css";

function SignIn() {
  const navigate = useNavigate();

  // Check if user is already logged in
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log("Google response:", credentialResponse); // Debug log

      const result = await googleLogin(credentialResponse.credential);
      console.log("Backend response:", result.data); // Debug log

      // Store the JWT token
      localStorage.setItem("token", result.data.access_token);

      // Redirect based on whether phone number is required
      if (result.data.phone_number_required) {
        navigate("/phone-setup");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login failed:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleGoogleError = () => {
    console.error("Google Sign In failed");
    // You might want to show an error message to the user here
  };

  return (
    <div className="signin-container">
      <div className="signin-content">
        <h1>hey, welcome to stash! 👋</h1>
        <p className="app-description">
          i'm your personal reminder manager - i'll help you save links,
          remember important stuff, and stay organized through text messages
        </p>
        <div className="google-signin">
          <p className="signin-prompt">
            first things first - let's get you signed in:
          </p>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleError}
            useOneTap
          />
        </div>

        <div className="how-it-works">
          <h2>here's how it works:</h2>
          <div className="steps">
            <div className="step">
              <div className="step-number">1</div>
              <div className="step-content">
                <h3>send me any link to save it for later</h3>
                <p>articles, tweets, videos - whatever you want to remember!</p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <div className="step-content">
                <h3>i'll remind you about things</h3>
                <p>
                  groceries, tasks, people to catch up with - i've got your back
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <div className="step-content">
                <h3>chat naturally with me</h3>
                <p>
                  ask what's on your todo list, search your saved items, or mark
                  things as done
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="tips">
          <h2>pro tips:</h2>
          <ul>
            <li>📝 include sources on your links ("...from joe")</li>
            <li>📱 create a contact with a fun name + picture</li>
            <li>🔍 type "commands" to see everything i can do</li>
            <li>💻 check out your stash anytime at my-stash.com</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
