import React, { useState } from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import { useTheme } from "../contexts/ThemeContext";

function TagSelectionModal({
  isOpen,
  onClose,
  tags,
  selectedTags,
  onAddTag,
  onUpdateTags,
}) {
  const [newTag, setNewTag] = useState("");
  const [localSelectedTags, setLocalSelectedTags] = useState(selectedTags);
  const [showAddTag, setShowAddTag] = useState(false);
  const { isDarkMode } = useTheme();

  const handleAddTag = (e) => {
    e.preventDefault();
    if (newTag.trim()) {
      onAddTag(newTag.trim());
      setNewTag("");
      setShowAddTag(false);
    }
  };

  const handleTagToggle = (tag) => {
    const updatedTags = localSelectedTags.includes(tag)
      ? localSelectedTags.filter((t) => t !== tag)
      : [...localSelectedTags, tag];
    setLocalSelectedTags(updatedTags);
  };

  const handleSave = () => {
    onUpdateTags(localSelectedTags);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="modal-content tag-selection-modal">
        <button className="close-modal-button" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Manage Tags</h2>
        <div className="tag-list">
          {tags.map((tag) => (
            <button
              key={tag}
              className={`tag-chip ${
                localSelectedTags.includes(tag) ? "selected" : ""
              }`}
              onClick={() => handleTagToggle(tag)}
            >
              {tag}
            </button>
          ))}
          <button
            className="add-tag-chip"
            onClick={() => setShowAddTag(!showAddTag)}
          >
            <FaPlus />
          </button>
        </div>
        {showAddTag && (
          <form onSubmit={handleAddTag} className="add-tag-form">
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="New tag"
              className="tag-input"
            />
            <button type="submit" className="add-tag-button">
              Add Tag
            </button>
          </form>
        )}
        <div className="modal-actions">
          <button onClick={handleSave} className="save-button">
            Save
          </button>
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default TagSelectionModal;
