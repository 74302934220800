import React from "react";
import { FaSync, FaSun, FaMoon } from "react-icons/fa";
import "./DashboardControls.css";

const DashboardControls = ({
  isLoading,
  onRefresh,
  isDarkMode,
  onToggleTheme,
}) => {
  return (
    <div className="control-buttons">
      <button
        className="refresh-button"
        onClick={onRefresh}
        disabled={isLoading}
      >
        <FaSync /> {isLoading ? "Refreshing..." : "Refresh"}
      </button>
      <button className="theme-toggle" onClick={onToggleTheme}>
        {isDarkMode ? <FaSun /> : <FaMoon />}
      </button>
    </div>
  );
};

export default DashboardControls;
