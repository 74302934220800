import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { useTheme } from "../contexts/ThemeContext";
import MessageCard from "../components/MessageCard";
import TagSelectionModal from "../components/TagSelectionModal";
import ConfirmationModal from "../components/ConfirmationModal";
import MessageModal from "../components/MessageModal";
import DashboardHeader from "../components/DashboardHeader";
import SearchAndFilter from "../components/SearchAndFilter";
import DashboardControls from "../components/DashboardControls";
import Pagination from "../components/Pagination";
import * as api from "../services/api";

function Dashboard() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [copiedLinks, setCopiedLinks] = useState({});
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState("all");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [overdueMessages, setOverdueMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const [messagesPerPage, setMessagesPerPage] = useState(
    parseInt(localStorage.getItem("messagesPerPage") || "50"),
  );
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userTimezone, setUserTimezone] = useState("America/Los_Angeles");

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin"); // Redirect to sign-in if not logged in
    } else {
      fetchUserInfo();
      fetchMessages();
      fetchTags();
      fetchOverdueMessages();
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    if (activeTab === "overdue") {
      console.log("Switching to overdue tab, triggering fetch");
      fetchOverdueMessages();
    }
  }, [activeTab]);

  useEffect(() => {
    filterMessages();
  }, [messages, activeTab, activeFilters, searchQuery, overdueMessages]);

  useEffect(() => {
    fetchMessages();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, activeFilters]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newMessagesPerPage = parseInt(
        localStorage.getItem("messagesPerPage") || "50",
      );
      if (newMessagesPerPage !== messagesPerPage) {
        setMessagesPerPage(newMessagesPerPage);
        setCurrentPage(1); // Reset to first page when changing items per page
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [messagesPerPage]);

  const fetchUserInfo = async () => {
    try {
      const response = await api.fetchUserInfo();
      setUserPhoneNumber(response.data.phone_number);
    } catch (error) {
      console.error("Error fetching user info:", error);
      if (error.response && error.response.status === 401) {
        navigate("/signin");
      }
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await api.fetchUserInfo();
      setUserTimezone(response.data.timezone);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const fetchMessages = async () => {
    setIsLoading(true);
    try {
      const skip = (currentPage - 1) * messagesPerPage;
      const response = await api.fetchMessages(skip, messagesPerPage);

      if (response.data && response.data.items) {
        setMessages(response.data.items);
        setTotalMessages(response.data.total);
        filterMessages(response.data.items);
      } else {
        console.error("Unexpected response format:", response.data);
        setMessages([]);
        setTotalMessages(0);
        setFilteredMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      if (error.response && error.response.status === 401) {
        navigate("/signin");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await api.fetchTags();
      setTags(response.data);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const fetchOverdueMessages = async () => {
    try {
      const response = await api.fetchOverdueMessages();
      console.log("Fetched overdue messages:", response.data);
      setOverdueMessages(response.data);
      if (activeTab === "overdue") {
        setFilteredMessages(response.data);
      }
    } catch (error) {
      console.error("Error fetching overdue messages:", error);
      setOverdueMessages([]);
      if (activeTab === "overdue") {
        setFilteredMessages([]);
      }
    }
  };

  const handleDeleteClick = (messageId) => {
    setMessageToDelete(messageId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (messageToDelete) {
      try {
        await api.deleteMessage(messageToDelete);
        setMessages(
          messages.filter((message) => message.id !== messageToDelete),
        );
      } catch (error) {
        console.error("Error deleting message:", error);
      }
    }
    setIsDeleteModalOpen(false);
    setMessageToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setMessageToDelete(null);
  };

  const handleMarkAsDone = async (messageId) => {
    try {
      await api.markMessageAsDone(messageId);
      setMessages(
        messages.map((message) =>
          message.id === messageId ? { ...message, is_done: true } : message,
        ),
      );
    } catch (error) {
      console.error("Error marking message as done:", error);
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const handleAddTag = async (newTag) => {
    try {
      await api.addTag(newTag);
      fetchTags();
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  const handleDeleteTag = async (tag) => {
    try {
      await api.deleteTag(tag);
      fetchTags();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleOpenTagModal = (messageId) => {
    setSelectedMessageId(messageId);
    setIsTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setIsTagModalOpen(false);
    setSelectedMessageId(null);
  };

  const handleUpdateTags = async (updatedTags) => {
    try {
      await api.updateMessageTags(selectedMessageId, updatedTags);
      const updatedMessages = messages.map((message) =>
        message.id === selectedMessageId
          ? { ...message, tags: updatedTags }
          : message,
      );
      setMessages(updatedMessages);
      filterMessages(updatedMessages);
    } catch (error) {
      console.error("Error updating message tags:", error);
    }
  };

  const handleTagClick = (tag) => {
    setActiveFilters((prevFilters) => {
      if (prevFilters.includes(tag)) {
        return prevFilters.filter((t) => t !== tag);
      } else {
        return [...prevFilters, tag];
      }
    });
  };

  const clearFilters = () => {
    setActiveFilters([]);
  };

  const filterMessages = (messagesToFilter = messages) => {
    let filtered = messagesToFilter;

    if (activeTab === "overdue") {
      filtered = overdueMessages;
    } else if (activeTab !== "all") {
      filtered = filtered.filter(
        (message) => message.message_type.toLowerCase() === activeTab,
      );
    }

    if (activeTab !== "overdue") {
      if (activeFilters.length > 0) {
        filtered = filtered.filter((message) =>
          activeFilters.every((filter) => message.tags.includes(filter)),
        );
      }

      if (searchQuery.trim()) {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter(
          (message) =>
            message.content.toLowerCase().includes(query) ||
            message.title.toLowerCase().includes(query) ||
            message.tags.some((tag) => tag.toLowerCase().includes(query)),
        );
      }
    }

    setFilteredMessages(filtered || []);
  };

  const handleTypeChange = async (messageId, newType) => {
    try {
      const response = await api.updateMessageType(messageId, newType);
      if (response.data) {
        setMessages(
          messages.map((msg) =>
            msg.id === messageId ? { ...msg, message_type: newType } : msg,
          ),
        );
      }
    } catch (error) {
      console.error("Error updating message type:", error);
    }
  };

  const handleSnooze = async (messageId, snoozeDate) => {
    try {
      await api.snoozeMessage(messageId, snoozeDate);
      fetchMessages();
    } catch (error) {
      console.error("Error snoozing message:", error);
    }
    // Fetch new overdue messages
    fetchOverdueMessages();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleMessageUpdate = async (messageId, updatedData) => {
    try {
      const response = await api.updateMessage(messageId, updatedData);
      if (response.data) {
        setMessages(
          messages.map((msg) =>
            msg.id === messageId ? { ...msg, ...response.data } : msg,
          ),
        );
        setFilteredMessages((prevFiltered) =>
          prevFiltered.map((msg) =>
            msg.id === messageId ? { ...msg, ...response.data } : msg,
          ),
        );
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
  };

  const handleCloseModal = () => {
    setSelectedMessage(null);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const filteredCount = filteredMessages.length;

  return (
    <div className={`dashboard ${isDarkMode ? "dark-mode" : ""}`}>
      <DashboardHeader
        userPhoneNumber={userPhoneNumber}
        onSignOut={handleSignOut}
      />

      <div className="dashboard-header">
        <SearchAndFilter
          searchQuery={searchQuery}
          onSearch={handleSearch}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          overdueMessages={overdueMessages}
          activeFilters={activeFilters}
          onTagClick={handleTagClick}
          onClearFilters={clearFilters}
        />

        <DashboardControls
          isLoading={isLoading}
          onRefresh={fetchMessages}
          isDarkMode={isDarkMode}
          onToggleTheme={toggleDarkMode}
        />
      </div>

      <div className="message-grid">
        {filteredMessages.map((message, index) => (
          <MessageCard
            key={message.id}
            message={message}
            index={index}
            onDelete={handleDeleteClick}
            onMarkAsDone={handleMarkAsDone}
            copiedLinks={copiedLinks}
            setCopiedLinks={setCopiedLinks}
            onOpenTagModal={handleOpenTagModal}
            onTagClick={handleTagClick}
            onTypeChange={handleTypeChange}
            onSnooze={handleSnooze}
            onUpdate={(updatedData) =>
              handleMessageUpdate(message.id, updatedData)
            }
            onClick={() => handleMessageClick(message)}
            userTimezone={userTimezone}
          />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalMessages={activeTab !== "all" ? filteredCount : totalMessages}
        messagesPerPage={messagesPerPage}
        onPageChange={handlePageChange}
      />

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        message="Are you sure you want to delete this message?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        isDarkMode={isDarkMode}
      />

      <TagSelectionModal
        isOpen={isTagModalOpen}
        onClose={handleCloseTagModal}
        tags={tags}
        selectedTags={
          messages.find((m) => m.id === selectedMessageId)?.tags || []
        }
        onAddTag={handleAddTag}
        onUpdateTags={handleUpdateTags}
      />

      {selectedMessage && (
        <MessageModal
          message={selectedMessage}
          onClose={handleCloseModal}
          onUpdate={(updatedData) =>
            handleMessageUpdate(selectedMessage.id, updatedData)
          }
        />
      )}
    </div>
  );
}

export default Dashboard;
