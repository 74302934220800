import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Settings.css";
import { useTheme } from "../contexts/ThemeContext";
import * as api from "../services/api";
import { FaTimes } from "react-icons/fa";
import { updateTimezone, getTimezones } from "../services/api";

function Settings() {
  const { isDarkMode } = useTheme();
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const [messagesPerPage, setMessagesPerPage] = useState(
    localStorage.getItem("messagesPerPage") || "50",
  );
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tagsResponse, timezonesResponse, userResponse] =
          await Promise.all([
            api.fetchTags(),
            getTimezones(),
            api.fetchUserInfo(),
          ]);
        setTags(tagsResponse.data);
        setTimezones(timezonesResponse.data.timezones);
        setTimezone(userResponse.data.timezone);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleAddTag = async (e) => {
    e.preventDefault();
    if (!newTag.trim()) return;

    try {
      await api.addTag(newTag.trim());
      setNewTag("");
      api.fetchTags();
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  const handleDeleteTag = async (tag) => {
    try {
      await api.deleteTag(tag);
      api.fetchTags();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleMessagesPerPageChange = (e) => {
    const value = e.target.value;
    setMessagesPerPage(value);
    localStorage.setItem("messagesPerPage", value);
  };

  const handleTimezoneChange = async (e) => {
    const newTimezone = e.target.value;
    try {
      await updateTimezone(newTimezone);
      setTimezone(newTimezone);
    } catch (error) {
      console.error("Error updating timezone:", error);
    }
  };

  return (
    <div className={`settings ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="header">
        <h1>Settings</h1>
        <button onClick={() => navigate(-1)} className="back-button">
          Back to Dashboard
        </button>
      </div>

      <div className="settings-section">
        <h2>Display Settings</h2>

        <div className="setting-item">
          <label htmlFor="messagesPerPage">Messages per page:</label>
          <select
            id="messagesPerPage"
            value={messagesPerPage}
            onChange={handleMessagesPerPageChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div className="setting-item">
          <label htmlFor="timezone">Timezone:</label>
          <select
            id="timezone"
            value={timezone}
            onChange={handleTimezoneChange}
          >
            {timezones.map((tz) => (
              <option key={tz.value} value={tz.value}>
                {tz.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="tag-management">
        <h2>Tag Management</h2>
        <form onSubmit={handleAddTag}>
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            placeholder="Enter new tag"
            className="tag-input"
          />
          <button type="submit" className="add-tag-button">
            Add Tag
          </button>
        </form>
        <div className="tag-list">
          {tags.map((tag) => (
            <div key={tag} className="tag-chip">
              {tag}
              <button
                onClick={() => handleDeleteTag(tag)}
                className="delete-tag-button"
              >
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Settings;
