import React from "react";
import { Link } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import "./DashboardHeader.css";

const DashboardHeader = ({ userPhoneNumber, onSignOut }) => {
  return (
    <div className="dashboard-title">
      <div className="dashboard-title-text">
        <h1>Your stash</h1>
        <p>Phone Number: {userPhoneNumber}</p>
      </div>

      <div className="dashboard-title-controls">
        <Link to="/settings" className="settings-link">
          <FaCog />
        </Link>
        <button className="signout-button" onClick={onSignOut}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default DashboardHeader;
