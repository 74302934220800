export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const copyToClipboard = (text, messageId, setCopiedLinks) => {
  navigator.clipboard.writeText(text).then(
    () => {
      setCopiedLinks((prev) => ({ ...prev, [messageId]: true }));
      setTimeout(() => {
        setCopiedLinks((prev) => ({ ...prev, [messageId]: false }));
      }, 2000);
    },
    (err) => {
      console.error("Could not copy text: ", err);
    },
  );
};

export const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};
